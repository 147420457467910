.header{
    display: flex;
    justify-content: space-between;
}
.logo{
    width: 6rem;
    height: 6rem;
    padding: rem;
    /* border-radius: 10rem; */
}
.header-menu{
    display: flex;    
    list-style: none;
    gap: 2rem;
    color: white;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.header-menu>li:hover{
    cursor: pointer;
     color:orange; 
}
@media screen and (max-width: 768px){
  .header>:nth-child(2){
    position: fixed;
    right: 2rem;
    z-index: 99;
}
.header-menu{
    flex-direction: column;
    background-color: rgb(73, 73, 73);
    padding: 2rem; 
}
    .navv{
        /* width: 3rem; */
    }
}