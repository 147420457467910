.join{
    display: flex;
    padding: 3rem;
    gap: 10rem;
}
.left-j{
    color: white;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
}
.left-j>hr{
position: absolute;
width: 31rem;
border: 2px solid orange;
border-radius: 20px;
margin: -10px 0;
}
.right-j{
display: flex;
justify-content: center;
 align-items: flex-end;
}
.email-container{
    display: flex;
     height: 2rem;
    gap: 3rem;
    background-color:  #f5bc51f6;
    padding: 1rem 2rem;
}
.email-container>input{
    background-color: transparent;
    border: none;
    outline: none;
    color: darkslategray;
}

.btn-j{
background-color: orange;
color: white;    

}