.footer{
    display: flex;
    width: 5rem;
    height: 5rem;
    gap: 2rem;
    padding: 4rem;
    cursor: pointer;
    margin-left:30rem;
}
.logoo{
    display: flex;
    margin-left:40rem;
    width:10rem;
    height: 6rem;
}
.Footer-container{
position: relative;
justify-content: center;
align-items: center;
}
.Footer-container>hr{
border: 1px solid gray;
 width:60rem;
}