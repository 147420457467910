.media-img{
    height: 10rem;
    width: 20rem;
}
.media-img1{
    height: 10rem;
    width: 20rem;
}
.media-img2{
    height: 10rem;
    width: 20rem;
}
@media screen and (max-width: 768px){
    .media-img1{
        width: 15rem;
        height: 6rem;
    }
    .media-img2{
        height: 6rem;
        width: 15rem;
    }
    .media-img{
        height: 6rem;
        width: 15rem;
    }
    .card-1{
        width: 15rem;
        height: 2vh;
        gap: 1rem;
    }
    .card-1>img{
        
    }
}