.Testimonials{
    display: flex;
    gap: 1rem;
    padding: 2rem;
}
.left-t{
    display: flex;
    gap: 2rem;
    flex: 1 1;
    flex-direction: column;
    text-transform: uppercase;
    color: white;
}
.left-t>:nth-child(1){
    color: orange;
    font-weight: bold;
    font-size: 2rem;
}
.left-t>:nth-child(2),
.left-t>:nth-child(3)
{
    font-weight: bold;
    font-size: 3rem;
}
.left-t>:nth-child(4)
{
text-transform: none;
text-align: justify;
letter-spacing: 2px;
line-height: 40px;
}
.right-t{
    flex: 1;
    position: relative;
     
}
.imgg{
    width: 17rem;
    height: 20rem;
    position: absolute;
    /* object-fit: cover; */
    right: 8rem;
    top: 2rem;   
}

.right-t>:nth-child(1){
    position: absolute;
    width: 17rem;
    height: 20rem;
    border: 2px solid orange;
    background-color: transparent;
     right: 9rem;
     top: 0.9rem;
}
.right-t>:nth-child(2){
    position: absolute;
    width: 17rem;
    height: 19rem;
    right: 7rem;
    top: 4rem;
    background-color:#ffb517;
}
.arrow{
    display: flex;
    gap:1rem;
    position: absolute;
    bottom: 2rem;
    left: 1rem;
    
}
.arrow>img{
    width: 3rem;
    cursor: pointer;
}