.Programs{
    flex-direction: column;
    justify-content: space-between;
    display: flex;
    gap: 2rem;
    padding: 5rem;
}
.Programs-header{
    color: white;
    display: flex;
    gap: 5rem;
    text-transform: uppercase;
    font-weight: bold;
    justify-content:center;
    font-style: italic;
    font-size: 3rem;
}
.Media-card{
    display: flex;
    /* flex-direction: column; */
    gap: 4rem;
    width: 56rem;
    margin-left:4rem;
    /* padding: 15rem; */
}
@media screen and (max-width: 768px){
    .Programs-header{
        flex-direction: column;
        gap: 1rem;
        font-size: 1rem;
        align-items: center;
        justify-content: center;
        margin-top:-4rem;
        
    }
    .Media-card{
        flex-direction: column;
        position: relative;
        width:rem;
        height: 5rem;
        right: 7.5rem;
        gap: 10rem;
        margin-top:-2rem;
        
    }
}

