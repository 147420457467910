.program-header{
    color:rgba(0, 162, 255, 0.795);
    display: flex;
    gap: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    justify-content:center;
    font-style: italic;
    font-size: 3rem;
    padding: 1.5rem;
}
@media screen and (max-width: 768px){
    .plans{
        /* flex-direction: column; */
    }
    .program-header{
       margin-left: 3rem;
        font-size: 1rem;
        flex-direction: column;
    }
    .TextExample{
        margin-top: -7rem;
        width: 15rem;
        /* height: 10rem; */
        position: relative;
    }
   
}