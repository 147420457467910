
.hero{
  display: flex;
  justify-content: space-between;
}
.left-h{
    padding: 2rem;
    padding-top:1em; 
    flex: 3;
    display: flex;
    gap: 2rem;
    flex-direction: column;
}
.right-h{
    flex: 1 ;
    position: relative;
    /* background-color: orange; */
}
.the-best-ad{
    margin-right: 4rem;
    background-color: rgb(66, 66, 66);
    border-radius: 4rem;
    width: fit-content;
    padding: 20px 16px;
    text-transform: uppercase;
    color: white;
    display: flex;
    position:relative ;
    align-items: center;
    justify-content: flex-start;
}
.the-best-ad>span{
    z-index: 2;
}
.the-best-ad>div{
    position: absolute;
    background-color: orange;
    width: 5.4rem;
    height: 80%;
    left: 8px;
    border-radius: 3rem;
    z-index: 1;

}
.hero-text{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-transform: uppercase;
    font-size: 4.5rem;
    font-weight: bold;
    color: white;
    text-overflow: inherit;
}
.hero-text>div:nth-of-type(3){
    font-size: 1rem;
    font-weight: 200;
    /* font-family:"200"; */
    text-transform:none;
    letter-spacing: 1px;
    width: 80%;
}
.figures{
    display: flex;
    gap: 2rem;
}
.figures>div{
    display: flex;
    flex-direction: column;
}
.figures>div>span:nth-of-type(1){
    color: white;
    font-size: 2rem;
    
}
.figures>div>span:nth-of-type(2){
    color:gray;
    /* font-size: 2rem; */
    /* font-style:initial; */
    text-transform: uppercase;
}
.hero-buttons{
    display: flex;
    gap: 1rem;
    font-weight: normal;
    
}
.hero-buttons>:nth-child(1){
    color: white;
    background-color: orange;
    width: 8rem;
    border: 2px solid orange;
}
.hero-buttons>:nth-child(2){
    color: white;
    background-color: transparent;
    width: 8rem;
    border: 2px solid orange;
}
.right-h>.btn{
    position: absolute;
    right: 3rem;top: 2rem;color: black;
}
.heart-rate{
    display: flex;
    flex-direction: column;
    gap:1rem;
    background-color: rgb(56, 54, 54);
    width: fit-content;
    padding:1rem;
    /* text-align: center; */
    align-items: flex-start;
    border-radius: 5px;
    position: absolute;
    right: 4rem;
    top: 7rem;

}
.hear{
    width: 3rem;
    /* height: 5rem; */
}
.heart-rate>:nth-child(2){
    color: gray;

}
.heart-rate>:nth-child(3){
    color:white;
    font-size: 1.5rem;
}
.hero-image{
    /* width: 10rem; */
    position: absolute;
    right: 10rem;
    top:5rem;
    width: 30rem;
}
.calories{
    display: flex;
    /* font-size: 2rem; */
    /* gap: 2rem; */
    flex-direction: column;
    border-radius: 5px;
    background-color:rgb(56, 54, 54);
    width: fit-content;
    /* height: 10rem; */
    position: absolute;
    text-align: center;
    top: 30rem;
    right: 40rem;
}
.calories-img{
    width: 7rem;
}
.calories>:nth-child(3){
 color: white;
 font-size: 1.5rem;
}
.calories>:nth-child(2){
    /* color: white; */
    font-size: 1.3rem;
    text-transform: capitalize;
    color:gray;
   }

   @media screen and (max-width: 768px) {
    .hero{
        flex-direction:column;
    }
    .the-best-ad{
        width: 14rem;
        margin-top: 0;
        font-size: small;
        align-self: center;
        transform: scale(0.8);
    }
    .stroke-text{
        font-size: 2rem;

    }
    .hero-text{
        font-size: 2rem;
        align-items: center;
        justify-content: center;
    }
    .hero-text>div:nth-child(3){
        font-size: small;
        font-weight: 200;
    }
    .hero-buttons{
        justify-content: center;
    }
    .figures>div>span:nth-of-type(1){
        font-size: large;

    }
    .figures>div>span:nth-of-type(2){
        font-size: x-small;
        color: orange;
    }
    .right-h{
        position: relative;
        background: none;
    }
    .heart-rate{
        left: 1rem;
        top:-1rem;
        
    }
    .calories{
        position: relative;
        top: -1rem;
        left: 1rem;
    }
    .calories>img{
        width: 6.5rem;
    }
    .hero-image{
        position: relative;
        width: 15rem;
        left: 4rem;
        top: 4rem;
        align-self: center;
    }
   }