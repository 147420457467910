
.all{
    /* background-color: rgba(5, 5, 5, 0.863); */
    background-image: url(../src/Components/images/pic1.jpg);
    width: auto;
    height: auto;
    opacity: 0.9;
    background-size:cover ;
    background-repeat: no-repeat;    
}
#programs{
    background-image: url(../src/Components/images/back1.jpg);
    width: auto;
    height:120vh;
    opacity: 0.9;
    background-size:cover ;
    background-repeat: no-repeat;    
}

.stroke-text{
    color: transparent;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;

}
.stroke-text2{
    color: transparent;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: gold;

}
.btn{
    background-color: white;
    padding: 0.5rem;
    font-weight: bold;
    border: 4px solid transparent;
    transition: 300ms all ;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn:hover{
     cursor: pointer;
     
}
/* .hr{
    display: flex;
    padding: 10rem;
} */
.Reason{
   background-image: url(../src/Components/images/bacc3.jpg);
    width: auto;
    height: 120vh;
    opacity: 0.9;
    background-size:cover ;
    
    background-repeat: no-repeat;    
}
.plans{
    background-image: url(../src/Components/images/backk.jpg);
    width: auto;
    height: 90vh;
    opacity: 0.9;
    background-size:cover ;
    background-repeat: no-repeat;
}
.Testimonial{
    background-image: url(../src/Components/images/bk1.jpg);
    width: auto;
    height: 140vh;
    opacity: 0.9;
    background-size:cover ;

}