.Reasons{
padding: 2rem;
display: flex;
gap:3rem ;
}
.why{
    width: 13rem;
    height: 32rem;
    grid-row: 1/3;
    
}
.why1{
    width: 15rem;
    height: 15rem;

}
.why2{
    width: 15rem;
    height: 15rem;
}
.why3{
    width: 15rem;
    height: 15rem;
}
.left-r{
    flex: 1 1 ;
    display: grid;
     grid-template-columns: repeat(3, 1fr);
     gap: 1rem;
     grid-auto-rows: 1fr;
}
.left-r>img{
   object-fit: cover;
   
}
.right-r{
    flex: 1 1 ;
    text-transform: uppercase;
    gap: 1rem;
    display: flex;
    flex-direction: column;
}
.right-r>span{
    font-weight:bold ;
    color: orange;
}
.right-r>div:nth-child(2){
    color: white;
    font-size: 3rem;
    font-weight: bold;
    gap: 1rem;

}
.tick{
    width: 1.5rem;
  

}
.some{
    font-size: 2.5rem;
}
/* .right-r>div:nth-child(3){
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
} */
.tick-text{
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: capitalize;
   
}
.details-r{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.partners{
    width: 1rem;
    display: flex;
    /* gap: 1rem; */
}
.partners>img{
    width: 7rem;
}
.style{
  padding: 1rem;
  font-size: 2rem;
}

@media screen and (max-width: 768px){
    .Reasons{
        flex-direction: column;
    }
    .left-r{
        grid-auto-rows:auto ;
        overflow: hidden;
    }
    .left-r>:nth-child(1){
        width: 7rem;
        height: 17rem;
        background-size: cover;
    }
    .left-r>:nth-child(2){
        width: 10rem;
        height: 9rem;
        /* background-size: cover; */
    }
    .left-r>:nth-child(3){
        width: 10rem;
        height: 9rem;
        /* background-size: cover; */
    }
    .left-r>:nth-child(4){
        width: 10rem;
        height: 6rem;
        /* background-size: cover; */
    }
    .left-r>:nth-child(5){
        width: 10rem;
        height: 6rem;
        /* background-size: cover; */
    }
    .right-r>:nth-child(1){
        font-size: small;
        margin-top: -3rem;
    }
    .tick-text{
        font-size: 0.8rem;
    }
    .tick{
        width: 0.8rem;
    }
    .whyy{
        
        font-size: 1rem;
    }
    .stroke-text{
        font-size: 1rem;
    }
    .choos{
        font-size: 1rem;

    }
    .us{
        margin-top: -2rem;
    }
    .style{
        font-size: 1rem;
        /* color: orange; */
    }
    .partners{
        width: 10px;
        height: 5rem;
        margin-left:-3rem;
    }
    

}